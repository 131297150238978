'use strict';

const ADDON_ID = "@chakra-ui/storybook-addon";
const COLOR_MODE_TOOL_ID = `${ADDON_ID}/color-mode-tool`;
const DIRECTION_TOOL_ID = `${ADDON_ID}/direction-tool`;
const EVENTS = {
  TOGGLE_COLOR_MODE: `${ADDON_ID}/toggleColorMode`,
  TOGGLE_DIRECTION: `${ADDON_ID}/toggleDirection`
};

exports.ADDON_ID = ADDON_ID;
exports.COLOR_MODE_TOOL_ID = COLOR_MODE_TOOL_ID;
exports.DIRECTION_TOOL_ID = DIRECTION_TOOL_ID;
exports.EVENTS = EVENTS;
