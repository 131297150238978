'use strict';

var managerApi = require('@storybook/manager-api');
var colorModeTool = require('./utils/color-mode-tool.cjs');
var constants = require('./utils/constants.cjs');
var directionTool = require('./utils/direction-tool.cjs');

managerApi.addons.register(constants.ADDON_ID, () => {
  const match = ({ viewMode }) => Boolean(viewMode && viewMode.match(/^(story|docs)$/));
  managerApi.addons.add(constants.DIRECTION_TOOL_ID, {
    type: managerApi.types.TOOL,
    title: "Direction",
    render: directionTool.DirectionTool,
    match
  });
  managerApi.addons.add(constants.COLOR_MODE_TOOL_ID, {
    type: managerApi.types.TOOL,
    title: "Color Mode",
    render: colorModeTool.ColorModeTool,
    match
  });
});
