'use strict';

var jsxRuntime = require('react/jsx-runtime');
var React = require('react');
var managerApi = require('@storybook/manager-api');
var components = require('@storybook/components');
var constants = require('./constants.cjs');

const LTRIcon = (props) => /* @__PURE__ */ jsxRuntime.jsxs(
  "svg",
  {
    stroke: "currentColor",
    fill: "currentColor",
    strokeWidth: "0",
    viewBox: "0 0 24 24",
    height: "1em",
    width: "1em",
    style: {
      transform: "scale(1.2)"
    },
    ...props,
    children: [
      /* @__PURE__ */ jsxRuntime.jsx("path", { fill: "none", d: "M0 0h24v24H0V0z" }),
      /* @__PURE__ */ jsxRuntime.jsx("path", { d: "M9 4v4c-1.1 0-2-.9-2-2s.9-2 2-2m8-2H9C6.79 2 5 3.79 5 6s1.79 4 4 4v5h2V4h2v11h2V4h2V2zm0 12v3H5v2h12v3l4-4-4-4z" })
    ]
  }
);
const RTLIcon = (props) => /* @__PURE__ */ jsxRuntime.jsxs(
  "svg",
  {
    stroke: "currentColor",
    fill: "currentColor",
    strokeWidth: "0",
    viewBox: "0 0 24 24",
    height: "1em",
    width: "1em",
    style: {
      transform: "scale(1.2)"
    },
    ...props,
    children: [
      /* @__PURE__ */ jsxRuntime.jsx("path", { fill: "none", d: "M0 0h24v24H0V0z" }),
      /* @__PURE__ */ jsxRuntime.jsx("path", { d: "M10 4v4c-1.1 0-2-.9-2-2s.9-2 2-2m8-2h-8C7.79 2 6 3.79 6 6s1.79 4 4 4v5h2V4h2v11h2V4h2V2zM8 14l-4 4 4 4v-3h12v-2H8v-3z" })
    ]
  }
);
const DirectionTool = () => {
  const [globals, setGlobals] = managerApi.useGlobals();
  const [direction, setDirection] = managerApi.useAddonState(
    constants.DIRECTION_TOOL_ID,
    globals[constants.DIRECTION_TOOL_ID] || "ltr"
  );
  const targetDirection = direction !== "ltr" ? "ltr" : "rtl";
  React.useEffect(() => {
    setGlobals({ [constants.DIRECTION_TOOL_ID]: direction });
  }, [direction, setGlobals]);
  const toggleDirection = React.useCallback(() => {
    const channel = managerApi.addons.getChannel();
    channel.emit(constants.EVENTS.TOGGLE_DIRECTION, targetDirection);
    setDirection(targetDirection);
  }, [setDirection, targetDirection]);
  return /* @__PURE__ */ jsxRuntime.jsx(
    components.IconButton,
    {
      active: direction === "rtl",
      title: `Set layout direction to ${targetDirection}`,
      onClick: toggleDirection,
      children: targetDirection === "ltr" ? /* @__PURE__ */ jsxRuntime.jsx(LTRIcon, {}) : /* @__PURE__ */ jsxRuntime.jsx(RTLIcon, {})
    }
  );
};

exports.DirectionTool = DirectionTool;
